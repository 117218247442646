<template>
    <b-modal :id="modalId" modal-class="modal--chat-details modal-md" title="Chat Details" @hide="onCloseModal" hide-footer>
        <!--div>
            <h6>Chat Title</h6>
            <div class="chat-title-container">
                <input type="text">
                <b-button @click="saveTitle">Save</b-button>
            </div>
        </div-->
        <ul class="list-members">
            <li v-for="member in chatMembers" :key="member.id" :class="{'list-members__admin' : member.is_admin}">

                <div class="d-flex">
                    <div class="image-block image-block--rounded image-block--rounded--46" :id="'member-' + member.id">
                        <Avatar v-if="member.avatar || member.name "
                                :username="member.name"
                                :src="member.avatar"
                                :rounded=true
                                :size="46"
                        >
                        </Avatar>
                        <b-tooltip v-if="member.is_admin" :target="'member-' + member.id" placement="right"
                                   variant="light" title="Chat Admin"></b-tooltip>
                    </div>
                    <div>
                        <h5 class="heading5 heading5--secondary">{{ member.name }}</h5>
                        <p>
                            <a v-if="member.company_link" :href="'/' + member.company_link" target="_blank">{{ member.company_name }}</a>
                            <template v-else>{{ member.company_name }}</template> • {{ member.job_title }}
                        </p>
                    </div>

                    <div class="card" v-if="!member.is_admin && hasPermissionsToManipulateMembers && (canBeRemoved || canBeSetAsAdmin(member))">
                        <b-dropdown class="drag-n-drop icons-list dropdown-simple dropdown-small" boundary="viewport">
                            <b-dropdown-item v-if="canBeRemoved" class="icons-list__remove" @click="deleteChatMember(member.user_id)">
                                Remove from group
                            </b-dropdown-item>
                            <b-dropdown-item v-if="canBeSetAsAdmin(member)" class="icons-list__admin" @click="changeChatAdmin(member.user_id)">
                                Make admin
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </li>
        </ul>
    </b-modal>
</template>

<script>
    import chatsService from '@/services/chats.service'

    export default {
        components: {
            Avatar: () => import("vue-avatar")
        },
        name: 'ChatDetailsPopup',
        props: {
            roomInfo: {type: Object, required: true},
        },
        data() {
            return {
                chatMembers: [],
            }
        },
        computed: {
            modalId() {
                return "chat-details-modal-" + this.roomInfo.id
            },
            hasPermissionsToManipulateMembers() {
                return this.roomInfo.is_admin
            },
            canBeRemoved() {
                return this.chatMembers.length > 2
            },
            adminMember() {
                return this.chatMembers.filter(member => {
                    return member.is_admin === true
                })[0]
            },
        },
        methods: {
            open() {
                this.getChatMembers()
                this.$bvModal.show(this.modalId)
            },
            getChatMembers() {
                chatsService.getChatMembers(this.roomInfo.id).then(response => {
                    if (response.data.length) {
                        this.chatMembers = response.data
                    }
                })
            },
            canBeSetAsAdmin(member) {
                return this.adminMember.company_id === member.company_id
            },
            onCloseModal() {
                console.log('Closed')
            },
            saveTitle() {
                console.log('Title saved.')
            },
            deleteChatMember(memberId) {
                chatsService.deleteChatMember(this.roomInfo.id, memberId).then(response => {
                    if (response.data) {
                        let index = this.arrayHelper.findIndexByColumnValue(this.chatMembers, 'user_id', memberId)
                        if (index >= 0) {
                            this.chatMembers.splice(index, 1)
                        }

                        this.$emit('updateChat', this.roomInfo.id)
                    }
                })
            },
            changeChatAdmin(memberId) {
                chatsService.changeChatAdmin(this.roomInfo.id, memberId).then(response => {
                    if (response.data) {
                        this.roomInfo.is_admin = false;
                        this.getChatMembers()
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
